const defaultState = {
    user: {}
}
export default function reducer(
    state = defaultState, 
    { type, payload }) {
    switch(type) {
        case 'SET_USER_STATE' :
            return {
                ...state,
                user: payload
            }
    }

    return state
}
import { IonContent, IonPage, IonCard, IonCardContent, IonGrid, IonLoading, IonAlert,
        IonRow, IonCol, IonCardTitle, IonButton, IonItem, IonLabel, IonText, IonIcon, IonCardHeader, IonCardSubtitle} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Bookings.css';

import Toolbar from '../components/Toolbar/Toolbar';
import Footer from '../components/Footer/Footer';
import firebaseApp from '../components/firebase';

import * as moment from 'moment';
import timekit from 'timekit-sdk';
import { toast } from '../components/toast';
import { locationOutline } from 'ionicons/icons';

const Credits = () => {
  const user = firebaseApp.auth().currentUser;
  const fbUser = firebaseApp.firestore().collection('users').doc(user.uid);
  // const [ bookings, setBookings ] = useState([]);
  const [ bookings, setBookings ] = useState([]);
  const [ missedBookings, setMissedBookings ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ bookingsNum, setBookingsNum ] = useState();
  const [ missedBookingsNum, setMissedBookingsNum ] = useState();
  const [ credits, setCredits ] = useState(3);
  timekit.configure({
    appKey: 'live_api_key_wyVSRbJJiPmNbICWINJBRjmZwlcnZIQq',
  })
  
  const getUserBookings = () => {
    fbUser.collection("bookings")
    .where("end", ">", moment(Date.now()).format())
    .get().then(function(querySnapshot) {
      var bookingsList = [];
      querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const data = doc.data();
          // console.log(id, " => ", data);
          bookingsList.push({id, ...data});
          const bookingsSorted = bookingsList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)); 
      });
      setBookings(bookingsList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)))
      setBookingsNum(bookingsList.length)
    });
  }
  const twoWeeksAgo = moment(Date.now() - 12096e5).format();
  const getMissedUserBookings = () => {
    fbUser.collection("missedBookings")
      .where("start", ">", twoWeeksAgo)
      .get().then(function(querySnapshot) {
      var missedBookingsList = [];
      querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const data = doc.data();
          // console.log(id, " => ", data);
          missedBookingsList.push({id, ...data});
      });
      setMissedBookings(missedBookingsList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)))
      setMissedBookingsNum(missedBookingsList.length)
    });
  }
  
  useEffect(() => {
    getUserBookings();
    getMissedUserBookings();
    setTimeout(() => {
      setIsLoading(false)
    }, 1500);
  }, [])
  useEffect(() => {
    console.log(twoWeeksAgo)
    console.log("missed = ", missedBookings);
    console.log("bookings = ", bookings);
    console.log("missed num = ", missedBookingsNum);
    console.log("bookings num = ", bookingsNum);
  }, [missedBookings, bookings])
  return (
    <IonPage>
      <Toolbar />
      <IonLoading message="Please wait..." duration={2500} isOpen={isLoading} />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="bookingsCard align-items-center">
              <IonCardHeader>
                <IonCardSubtitle>
                  As of {moment(Date.now()).format('MMMM Do, hh:mm a')}
                </IonCardSubtitle>
                <IonCardTitle>
                  Credit Summary
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent className="niceCardContent ion-padding">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12" className="oneCard">
                      <div style={{height: 20}}/>
                      <p>Current Bookings: {bookingsNum}</p>
                      <p>Missed: {missedBookingsNum}</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
            <Footer user={user.email}/>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Credits;

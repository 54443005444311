import { IonContent, IonPage, IonCard, IonCardContent, IonCardTitle, IonLoading, IonItem, IonInput, IonButton, IonLabel } from '@ionic/react';
import React, { useState } from 'react';
import './Register.css';
import Toolbar from '../components/Toolbar/Toolbar';
// import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { Link, useHistory } from 'react-router-dom';
import { toast } from '../components/toast';
import { registerUser } from '../components/firebase';

const Register = () => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ cpassword, csetPassword ] = useState('');
  const [ busy, setBusy ] = useState(false);
  const history = useHistory();
  
  async function register() {
    setBusy(true);
    if (password !== cpassword) {
      return toast('Passwords do not match')
    }
    if (email.trim() === '' || password.trim() === '') {
      return toast('Email and Password are required')
    }

    const res = await registerUser(email, password)
    if (res) {
      toast('Nice Launch registration successful!')
    }
    history.replace('/details')
    setBusy(false);
  }
  return (
    <IonPage>
      <Toolbar />
      <IonLoading message="Registering you for Nice Launch..." duration={0} isOpen={busy} />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="registerCard align-items-center">
              <IonCardContent>
                <IonCardTitle>Create a Nice Launch account</IonCardTitle> 
                <div className="register">
                  <IonItem>
                    <IonLabel position="floating" className="inputLabel">Enter your email address</IonLabel>
                    <IonInput type="email" clearInput onIonChange={e => setEmail(e.target.value)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating" className="inputLabel">Enter a password</IonLabel>
                    <IonInput type="password" clearInput onIonChange={e => setPassword(e.target.value)}/>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating" className="inputLabel">Confirm your password</IonLabel>
                    <IonInput type="password" clearInput onIonChange={e => csetPassword(e.target.value)}/>
                  </IonItem>
                </div>
                <div className="confirm">
                  <IonButton expand="full" onClick={register}>Sign Up</IonButton>
                  <p>By signing up, you agree to our <a href="https://nicelaunch.ca/terms/" rel="noopener noreferrer" target="_blank">Terms</a> & <a href="https://nicelaunch.ca/privacy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.</p>
                </div>
                <div className="already">
                  <p>Already have a Nice Launch account? <br/><Link to="/login">Login</Link></p>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Register;

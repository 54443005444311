import React, {useState, useEffect} from 'react';
import { IonToolbar, IonTitle, IonImg, IonButton, IonButtons, IonPopover, IonItem} from '@ionic/react';
import inlineLogo from '../../assets/images/NL-03.png';
import inlineLogoDark from '../../assets/images/NL-04.png';
import { logoutUser } from '../firebase';
import { logOutOutline, menuOutline, listOutline, closeOutline, boatOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import firebaseApp from '../firebase';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoins } from '@fortawesome/free-solid-svg-icons'
import './Toolbar.css';

const Toolbar = () => {
    const user = firebaseApp.auth().currentUser;
    const [ isLoggedIn, setIsLoggedIn ] = useState(user === null ? false : true);
    const [ showMenu, setShowMenu ] = useState({open: false, event: null});
    const [isDark] = useState(
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
    const history = useHistory();
    function logout() {
      logoutUser();
      setIsLoggedIn(false);
      window.location.reload(true);
    }
    function goToLocations() {
      window.location.reload(true);
      // setShowMenu(false);
      setShowMenu({open: false, event: null})
    }
    function goToBookings() {
      history.replace('/my-bookings');
      setShowMenu({open: false, event: null})
    }
    // function goToCredits() {
    //   history.replace('/credits');
    //   setShowMenu({open: false, event: null})
    // }
    // function goToProfile() {
    //   history.replace('/my-profile');
    //   setShowMenu({open: false, event: null})
    // }
    useEffect(() => {
      // console.log(isLoggedIn);
    }, [isLoggedIn])
    return (
      <div>
      <IonToolbar className="toolbar">
        <IonTitle>
          <IonImg src={!isDark ? inlineLogo : inlineLogoDark} className="logo"/>
        </IonTitle>
        {isLoggedIn === false ? null :
        <IonButtons slot="end">
          <IonButton onClick={(e) => setShowMenu({open: true, event: e.nativeEvent})}>
            <IonIcon slot="icon-only" icon={showMenu.open ? closeOutline : menuOutline} />
          </IonButton>
        </IonButtons>
        }
      </IonToolbar>
      <IonPopover
        animated
        showBackdrop={true}
        backdropDismiss
        event={showMenu.event}
        cssClass="ion-padding"
        isOpen={showMenu.open}
        onDidDismiss={e => setShowMenu({open: false, event: null})}
      >
        <IonItem onClick={goToLocations} className="menuItem" lines="none" ><IonIcon icon={boatOutline}/> Book Now</IonItem>
        <IonItem onClick={goToBookings} className="menuItem" lines="none" ><IonIcon icon={listOutline} /> Bookings & Credits</IonItem>
        {/**<IonItem onClick={goToCredits} className="menuItem" lines="none" ><FontAwesomeIcon icon={faCoins} slot="start"/> My Credits</IonItem>**/}
        <IonItem lines="none" onClick={logout} className="menuItem"><IonIcon icon={logOutOutline} /> Log Out</IonItem>
      </IonPopover>
      </div>
    );
  };

  export default Toolbar;
import { IonContent, IonPage, IonCard, IonCardContent, IonCardTitle, IonLoading, IonSelectOption, IonSelect,
  IonItem, IonInput, IonButton, IonLabel, IonGrid, IonRow, IonCol, IonIcon, IonPopover } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Details.css';
import Toolbar from '../components/Toolbar/Toolbar';
import { informationCircleOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import { toast } from '../components/toast';
import firebaseApp from '../components/firebase';


const Details = () => {
  // const user = useSelector((state) => state.user);
  const [ firstName, setFirstName ] = useState();
  const [ lastName, setLastName ] = useState();
  // const [ email, setEmail ] = useState(user.email);
  const [ phone, setPhone ] = useState();
  const [ address1, setAddress1 ] = useState();
  const [ address2, setAddress2 ] = useState();
  const [ municipality, setMunicipality ] = useState('Innisfil');
  const [ postalCode, setPostalCode ] = useState();
  const [ licensePlate, setLicensePlate ] = useState();
  const [ boatSize, setBoatSize ] = useState();
  const [ busy, setBusy ] = useState(false);
  const [showPersonPopover, setShowPersonPopover] = useState({open: false, event: null});
  const [showContactPopover, setShowContactPopover] = useState({open: false, event: null});
  const [showVehiclePopover, setShowVehiclePopover] = useState({open: false, event: null});
  const [showBoatPopover, setShowBoatPopover] = useState({open: false, event: null});
  
  const history = useHistory();
  const fbDb = firebaseApp.firestore();
  const user = firebaseApp.auth().currentUser;
  const uid = user.uid;
  const fbUser = firebaseApp.firestore().collection('users').doc(uid);

  const getUserById = () => {
    fbUser.get().then(function(doc) {
      if (doc.exists) {
        // setUserDoc(doc.data());
        history.replace('/locations')
        // console.log(doc.data());
      } else {
        console.log('No such user!')
      }
    }).catch(function(error) {
      console.log("error getting doc", error);
    })
  }
  const addUserDoc = () => {
    setBusy(true);
    if (
      firstName === undefined || 
      lastName === undefined || 
      phone === undefined ||
      address1 === undefined ||
      postalCode === undefined ||
      licensePlate === undefined) {
        toast('All field are required. Please check your entries and try again.')
      } else {
          fbDb.collection("users").doc(uid).set({
              firstName,
              lastName,
              email: user.email,
              phone,
              address1: address1,
              address2: address2 !== undefined ? address2 : null,
              municipality,
              postalCode,
              licensePlate,
              boatSize
          })
          .then(function(res) {
              console.log("Document successfully written!");
              if (res) {
                toast('Your details have been saved successfully!')
              }
              history.replace('/locations')
          })
          .catch(function(error) {
            toast(error)
          });
        }
    setBusy(false);
  }
  useEffect(() => {
    // console.log(firstName, lastName, phone, address1, address2, postalCode, boatSize);
    getUserById();
  }, [])
  return (
    <IonPage>
      <Toolbar />
      <IonLoading message="Registering you for Nice Launch..." duration={0} isOpen={busy} />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="registerCard align-items-center">
              <IonCardContent>
              <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-padding">
                      <IonCardTitle>Your information <IonIcon onClick={(e) => setShowPersonPopover({open: true, event: e.nativeEvent})} icon={informationCircleOutline} className="infoIcon"/></IonCardTitle> 
                      <IonPopover
                        animated
                        showBackdrop={false}
                        backdropDismiss
                        event={showPersonPopover.event}
                        cssClass="ion-padding"
                        isOpen={showPersonPopover.open}
                        onDidDismiss={e => setShowPersonPopover({open: false, event: null})}
                      >
                        <p className="ion-padding">We request this information so we can confirm your booking at the launch sites.</p>
                      </IonPopover>
                      <div className="register">
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">First Name</IonLabel>
                          <IonInput type="text" required={true} autocapitalize='on' clearInput onIonChange={e => setFirstName(e.target.value)}/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Last Name</IonLabel>
                          <IonInput type="text" required={true} autocapitalize="on" clearInput onIonChange={e => setLastName(e.target.value)}/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Email Address</IonLabel>
                          <IonInput type="email" value={user.email} placeholder={user.email} inputMode="email" pattern="email" disabled/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Phone</IonLabel>
                          <IonInput type="tel" required={true} inputMode="tel" pattern="tel" clearInput onIonChange={e => setPhone(e.target.value)}/>
                        </IonItem>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-padding">
                      <IonCardTitle>Your contact information <IonIcon onClick={(e) => setShowContactPopover({open: true, event: e.nativeEvent})} icon={informationCircleOutline} className="infoIcon"/></IonCardTitle> 
                      <IonPopover
                        animated
                        showBackdrop={false}
                        backdropDismiss
                        event={showContactPopover.event}
                        className="ion-padding"
                        isOpen={showContactPopover.open}
                        onDidDismiss={e => setShowContactPopover({open: false, event: null})}
                      >
                        <p className="ion-padding">Some boat launches are open to the residents of their municipality only. If this is the case, you'll be required to confirm your residency at the launch site.</p>
                      </IonPopover>
                      <div className="register">
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Address Line 1</IonLabel>
                          <IonInput type="text" required={true} clearInput onIonChange={e => setAddress1(e.target.value)}/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Address Line 2</IonLabel>
                          <IonInput type="text" clearInput onIonChange={e => setAddress2(e.target.value)}/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Municipality</IonLabel>
                          <IonInput type="text" value={'Innisfil'} disabled autocapitalize="on"/>
                        </IonItem>
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Postal Code</IonLabel>
                          <IonInput type="text" required={true} className="uppercase" onIonChange={e => setPostalCode(e.target.value)}/>
                        </IonItem>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-padding">
                      <IonCardTitle>Vehicle information <IonIcon onClick={(e) => setShowVehiclePopover({open: true, event: e.nativeEvent})} icon={informationCircleOutline} className="infoIcon"/></IonCardTitle>
                      <IonPopover
                        animated
                        showBackdrop={false}
                        backdropDismiss
                        event={showVehiclePopover.event}
                        className="ion-padding"
                        isOpen={showVehiclePopover.open}
                        onDidDismiss={e => setShowVehiclePopover({open: false, event: null})}
                      >
                        <p className="ion-padding">License plates may be used to confirm your entry to municipal boat launch sites.</p>
                      </IonPopover>
                      <div className="register">  
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">License Plate #</IonLabel>
                          <IonInput type="text"  className="uppercase" clearInput onIonChange={e => setLicensePlate(e.target.value)}/>
                        </IonItem>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12" className="ion-padding">
                      <IonCardTitle>Boat information <IonIcon onClick={(e) => setShowBoatPopover({open: true, event: e.nativeEvent})} icon={informationCircleOutline} className="infoIcon"/></IonCardTitle>
                      <IonPopover
                        animated
                        showBackdrop={false}
                        backdropDismiss
                        event={showBoatPopover.event}
                        className="ion-padding"
                        isOpen={showBoatPopover.open}
                        onDidDismiss={e => setShowBoatPopover({open: false, event: null})}
                      >
                        <p className="ion-padding">Knowing the size of your boat help our ramp attendants optimize launches/removals so everything moves more quickly!</p>
                      </IonPopover>
                      <div className="register">  
                        <IonItem>
                          <IonLabel position="floating" className="inputLabel">Size in feet</IonLabel>
                          <IonInput type="number"  className="uppercase" clearInput onIonChange={e => setBoatSize(e.target.value)}/>
                        </IonItem>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <div className="confirm">
                  <IonButton expand="full" onClick={addUserDoc}>Confirm Details</IonButton>
                </div>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Details;

import { IonContent, IonPage, IonCard, IonCardContent, IonGrid, 
        IonRow, IonCol, IonIcon, IonCardTitle, IonButton} from '@ionic/react';
import React from 'react';
import './Confirmation.css';
import {checkmarkCircleOutline} from 'ionicons/icons';
import Toolbar from '../components/Toolbar/Toolbar';
// import firebaseApp from '../components/firebase';
import { useHistory } from 'react-router';
// console.log(timekit);

const Confirmation = (props) => {
  const history = useHistory();
  // const user = firebaseApp.auth().currentUser;

  const again = () => {
    window.location.reload(true);
  }
  const goToBookings = () => {
    history.replace('/my-bookings');
  }

  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="confirmationCard align-items-center">
              <IonCardContent className="niceCardContent ion-padding">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12" className="oneCard">
                      <IonCardTitle className="thanks">Thank you for using Nice Launch!</IonCardTitle>
                      <div style={{height: 20}}/>
                      <IonIcon color="success" icon={checkmarkCircleOutline} className="successIcon" />
                      <p className="received first">Your booking has been received.</p>
                      <p className="received"> You can view all your bookings at any time by visiting "My Bookings".</p>
                      <p className="ion-text-center"><IonButton onClick={goToBookings}>Go To My Bookings</IonButton></p>
                      <p className="received">Remember to bring your Park Pass or other document, such as a utility bill, that shows your Innisfil address.
</p>
                      <p className="received">Arrive no earlier than 15 minutes before your launch time.</p>
                      <p className="signoff">Happy boating!</p>
                      <p className="theteam">The Nice Launch Team</p>

                      <p style={{paddingBottom: 20}}>Want to book another launch?</p>
                      <IonButton style={{marginBottom: 20}} onClick={again}>Book now!</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Confirmation;

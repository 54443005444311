import { IonContent, IonPage, IonCard, IonCardContent, IonGrid, 
        IonRow, IonCol, IonCardTitle, IonCardHeader, IonCardSubtitle,} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Locations.css';
import Toolbar from '../components/Toolbar/Toolbar';
import firebaseApp from '../components/firebase';
import Footer from '../components/Footer/Footer';

const Locations = () => {
  // const [ launchRemoval, setLaunchRemoval ] = useState('Launch');
  const [ userDoc, setUserDoc ] = useState({});
  
  const user = firebaseApp.auth().currentUser;
  const fbUser = firebaseApp.firestore().collection('users').doc(user.uid);

  const getUserById = () => {
    fbUser.get().then(function(doc) {
      if (doc.exists) {
        const data = doc.data();
        setUserDoc(data);
        
      } else {
        console.log('No such user!')
      }
    }).catch(function(error) {
      console.log("error getting doc", error);
    })
  }
  

  useEffect(() => {
    getUserById();
  }, [])
  
  return (
    <IonPage>
      <Toolbar/>
      <IonContent>
      <div className="inTheMiddle">

        <IonCard className="locCard">
          <IonCardHeader color="primary">
            <IonCardSubtitle></IonCardSubtitle>
            <IonCardTitle></IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <h2>Closed for the season</h2>
                <br/>
                <p>As of September 21, NiceLaunch booking for Innisfil boat launches is closed for the season. Please refer to the <a href="https://innisfil.ca/boatbooking/" target="_blank" rel="noopener noreferrer">town's website</a> for more information.</p>
                <br/>
                <p>If you'd like to complete a short survey regarding your experience with NiceLaunch this past season, you can do so <a href="https://www.getinvolvedinnisfil.ca/boatlaunch" target="_blank" rel="noopener noreferrer">here</a>.</p>
                <br/>
                <p>Thank you, and happy boating!</p>
                <p><strong>The NiceLaunch Team</strong></p>
              </IonCol>
            </IonRow>
          </IonGrid>
          </IonCardContent>
        </IonCard>
        
        <Footer user={userDoc.email}/>

        
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Locations;

import { IonContent, IonPage, IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonItem, IonInput, IonButton, IonIcon, IonLabel, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import './Login.css';
import {logoFacebook, logoGoogle} from 'ionicons/icons';
import Toolbar from '../components/Toolbar/Toolbar';
import { loginUser } from '../components/firebase';
import { toast } from '../components/toast';
import { setUserState } from '../redux/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const Login = (props) => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ busy, setBusy ] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  async function login(event) {
    event.preventDefault();
    setBusy(true);
    const res = await loginUser(email, password);
    if(res) {
      dispatch(setUserState(res.uid));
      history.replace('/details');
      setEmail('');
      setPassword('');
      toast('Nice Launch checking for your profile')
    }
    
    setBusy(false);
  }
  return (
    <IonPage>
      <Toolbar />
      <IonLoading message="Please wait..." duration={0} isOpen={busy} />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="loginCard align-items-center">
              <IonCardContent className="loginCardContent ion-no-padding">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" sizeXs="12" className="loginCardLeft">
                    <form onSubmit={login}>
                      <IonItem>
                        <IonLabel position="floating" className="inputLabel">Email</IonLabel>
                        <IonInput type="email" clearInput onIonChange={e => setEmail(e.target.value)}/>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating" className="inputLabel">Password</IonLabel>
                        <IonInput type="password" clearInput onIonChange={e => setPassword(e.target.value)}/>
                      </IonItem>
                      <div className="loginButtons">
                        <IonButton type="submit" className="loginButton">Sign in</IonButton>
                        <span className="or">or</span>
                        {/**<IonButton fill={'outline'} className="loginButton full"><span><IonIcon icon={logoFacebook} />&nbsp; Sign in with Google</span></IonButton>
  <IonButton fill={'outline'} className="loginButton full"><span><IonIcon icon={logoGoogle} />&nbsp; Sign in with Facebook</span></IonButton>**/}
                        <IonButton fill={'clear'} routerLink="/password-reset" className="loginButton">Forgot Password?</IonButton>
                      </div>
                    </form>
                    </IonCol>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" sizeXs="12" className="loginCardRight">
                      <div className="">
                        <h2 className="loginCTA"><span>Create an account</span></h2>
                        <IonButton routerLink="/register" color="light" fill="outline" className="loginButton">Create an account</IonButton>
                        {/**<IonButton routerLink="/locations" color="light" fill="clear" className="loginButton">Proceed as a guest</IonButton>**/}
                        <p className="loginReason">Why is an account needed?  If you plan on launching your boat more than once, having your details saved will make things faster and easier. Having an account will also allow you to cancel a booking and make a new one if your plans change.</p>       
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

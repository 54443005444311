import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonLoading } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Login from './pages/Login';
import Register from './pages/Register';
import Details from './pages/Details';
import Locations from './pages/Locations';
import Confirmation from './pages/Confirmation';
import Bookings from './pages/Bookings';
// import Profile from './pages/Profile';
import Calendar from './pages/Calendar';
import ResetPassword from './pages/ResetPassword';
import { getCurrentUser } from './components/firebase';
import { setUserState } from './redux/actions';
import { useDispatch } from 'react-redux';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Credits from './pages/Credits';

const RoutingSystem = () => {
  return (
  <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/login" component={Login} exact={true} />
        <Route path="/register" component={Register} exact={true} />
        <Route path="/details" component={Details} exact={true} />
        <Route path="/locations" component={Locations} exact={true} />
        <Route path="/my-bookings" component={Bookings} exact={true} />
        <Route path="/credits" component={Credits} exact={true} />
        {/**<Route path="/my-profile" component={Profile} exact={true} />**/}
        <Route path="/password-reset" component={ResetPassword} exact={true} />
        <Route path="/confirmation" component={Confirmation} />
        <Route exact path="/" render={() => <Redirect to="/login" />} />
      </IonRouterOutlet>
  </IonReactRouter>
 
  )
}
const App = () => {
  const [ busy, setBusy ] = useState(true);
  const dispatch = useDispatch()
  useEffect(() => {
    getCurrentUser().then(user => {
      // console.log(user.uid)
      if (user) {
        //Logged in
        dispatch(setUserState(user.uid))
        window.history.replaceState({}, '', '/locations')
      } 
      else {
        window.history.replaceState({}, '', '/')
      }
      setBusy(false)
    })
  }, [])

  return (
  <IonApp>
    {busy ? <IonLoading
              cssClass='my-custom-class'
              isOpen={busy}
              onDidDismiss={() => setBusy(false)}
              message={'Please wait...'}
              duration={5000}
            /> : <RoutingSystem/>}
  </IonApp>
  )
};

export default App;

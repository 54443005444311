import { IonContent, IonPage, IonCard, IonCardContent, IonCardSubtitle, IonLoading, IonAlert, IonSegment, IonSegmentButton,
   IonCardTitle, IonButton, IonItem, IonLabel, IonText, IonIcon, IonCardHeader, IonList, IonBadge} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Bookings.css';

import Toolbar from '../components/Toolbar/Toolbar';
import Footer from '../components/Footer/Footer';
import firebaseApp from '../components/firebase';

import * as moment from 'moment';
import timekit from 'timekit-sdk';
import { toast } from '../components/toast';
import { locationOutline } from 'ionicons/icons';

const Bookings = () => {

  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  const user = firebaseApp.auth().currentUser;
  const fbUser = firebaseApp.firestore().collection('users').doc(user.uid);
  // const [ bookings, setBookings ] = useState([]);
  const [ bookings, setBookings ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ selectedBooking, setSelectedBooking ] = useState('');
  const [ showAlert, setShowAlert ] = useState(false);
  //Segment toggle
  const [ bookingsMissed, setBookingsMissed ] = useState('bookings');
  //Missed bookings array
  const [ missedBookings, setMissedBookings ] = useState([]);
  const [ missedBookingsNum, setMissedBookingsNum ] = useState();
  const [ userDoc, setUserDoc ] = useState({});
  const [ isSuperUser, setIsSuperUser ] = useState(null);
  const [ credits, setCredits ] = useState(3);
  const CurrentDate = new Date();
  timekit.configure({
    appKey: 'live_api_key_wyVSRbJJiPmNbICWINJBRjmZwlcnZIQq',
  })
  
  const getUserById = () => {
    fbUser.get().then(function(doc) {
      if (doc.exists) {
        const data = doc.data();
        setUserDoc(data);
        // console.log(data);
        if(data.superUser === true) {
          setIsSuperUser(true);
          console.log('Super');
          setIsLoading(false);
        } else {
          setIsSuperUser(false);
          console.log('Normal');
          setIsLoading(false);
        }
      } else {
        console.log('No such user!')
      }
    }).catch(function(error) {
      console.log("error getting doc", error);
    })
  }
  const getUserBookings = () => {
    fbUser.collection("bookings")
    .where("end", ">", moment(Date.now()).format())
    .get().then(function(querySnapshot) {
      var bookingsList = [];
      querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const data = doc.data();
          console.log(id, " => ", data);
          // if (moment(data.start).format('YYYY-MM-DD hh:mm a') < moment(CurrentDate).format('YYYY-MM-DD hh:mm a')) {
          //   return null;
          // } else {
          //   bookingsList.push({id, ...data});
          // }
          bookingsList.push({id, ...data});
          const bookingsSorted = bookingsList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)); 
          setBookings(bookingsSorted);
      });
  });
  }
  const twoWeeksAgo = moment(Date.now() - 12096e5).format();
  const getMissedUserBookings = () => {
    fbUser.collection("missedBookings")
      .where("start", ">", twoWeeksAgo)
      .get().then(function(querySnapshot) {
      var missedBookingsList = [];
      querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const data = doc.data();
          // console.log(id, " => ", data);
          missedBookingsList.push({id, ...data});
      });
      setMissedBookings(missedBookingsList.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0)))
      setMissedBookingsNum(missedBookingsList.length)
    });
  }
  function alertIt(bookingId) {
    setSelectedBooking(bookingId);
    setShowAlert(true);
  }
  function confirmCancel(){
    setIsLoading(true);
    fbUser.collection("bookings").doc(selectedBooking).delete().then(function() {
        console.log("Document successfully deleted!");
        toast('Booking canceled!');
        timekit.updateBooking(
          { 
            id: selectedBooking, 
            action: "cancel",
          }
        )
        setBookings(bookings.filter(item => item.id !== selectedBooking))
      }).catch(function(error) {
          console.error("Error canceling booking: ", error);
      });
    
    setIsLoading(false);
  }
  const again = () => {
    window.location.reload(true);
  }
  useEffect(() => {
    getUserById();
    getUserBookings();
    getMissedUserBookings();
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
    // console.log(moment(CurrentDate).format('YYYY-MM-DD hh:mm a'))
  }, [])
  useEffect(() => {
    // console.log('bookings = ', bookingsNum);
    // console.log('missed bookings = ', missedBookingsNum);
    if(isSuperUser === true){
      setCredits(9999999)
    } else {
      setCredits(3 - bookings.length - missedBookingsNum);
    }
  }, [ bookings, missedBookingsNum])
  
  return (
    <IonPage>
      <Toolbar />
      <IonLoading message="Please wait..." duration={2500} isOpen={isLoading} />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="bookingsCard align-items-center">
              <div className="ion-padding">
                <IonSegment value={bookingsMissed} onIonChange={e => setBookingsMissed(e.detail.value)}>
                  <IonSegmentButton value="bookings">
                    <IonLabel>Bookings</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="missed">
                    <IonLabel>Missed</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton value="credits">
                    <IonLabel>Credits</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              <IonCardHeader>
                <IonCardSubtitle>
                  As of {moment(Date.now()).format('MMMM Do, hh:mm a')}
                </IonCardSubtitle>
                <IonCardTitle>
                    {bookingsMissed === 'bookings' ? 'Upcoming Bookings': bookingsMissed === 'missed' ? 'Missed Bookings' : 'Credits Summary'}
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent className="niceCardContent ion-padding">
              {bookingsMissed === 'bookings' ?
                <IonList style={prefersDark.matches === false ? {backgroundColor: '#ffffff'} : {backgroundColor: '#1e1e1e'}}>
                  {bookings.length > 0 ? bookings.map((booking, i) => (
                    <IonItem 
                      // className="timeSlot"
                      key={i}>
                        <IonLabel className="ion-text-wrap">
                          <IonText color="primary">
                            <h3 className="dateTime">{moment(booking.start).format('MMMM Do') + ' @ ' + moment(booking.start).format('hh:mm a')}</h3>
                          </IonText>
                          <p className="where"><IonIcon icon={locationOutline}/> {booking.description}</p>
                          {/**<p className="where">{booking.where}</p>**/}
                        </IonLabel>
                        <IonButton onClick={() => alertIt(booking.id)} fill="outline" color="danger" slot="end">
                          Cancel
                        </IonButton>
                    </IonItem>
                  ))
                  : 
                  <div style={prefersDark.matches === false ? {backgroundColor: '#ffffff'} : {backgroundColor: '#1e1e1e'}}>
                    <p>No bookings found.</p>
                    <IonButton style={{marginBottom: 20, marginTop: 20}} onClick={again}>Book now!</IonButton>
                  </div>
                  }
                </IonList>
              : bookingsMissed === 'missed' ?
                <IonList style={prefersDark.matches === false ? {backgroundColor: '#ffffff'} : {backgroundColor: '#1e1e1e'}}>
                  {missedBookings.length > 0 ? missedBookings.map((booking, i) => (
                    <IonItem 
                      // className="timeSlot"
                      key={i}>
                        <IonLabel className="ion-text-wrap">
                          <IonText color="primary">
                            <h3 className="dateTime">Missed: {booking.description}, {moment(booking.start).format('MMMM Do, hh:mm a')}</h3>
                          </IonText>
                          <IonText color="primary">
                            <h3 className="expires">Expires: {moment(Date.parse(booking.start) + 12096e5).format('MMMM Do') + ' @ ' + moment(Date.parse(booking.start) + 12096e5).format('hh:mm a')}</h3>
                          </IonText>
                        </IonLabel>
                    </IonItem>
                  ))
                  : 
                  <div style={prefersDark.matches === false ? {backgroundColor: '#ffffff'} : {backgroundColor: '#1e1e1e'}}>
                    <p>No bookings found.</p>
                    <IonButton style={{marginBottom: 20, marginTop: 20}} onClick={again}>Book now!</IonButton>
                  </div>
                  }
                </IonList>
                :
                <div className="ion-text-center">
                  <IonList style={prefersDark.matches === false ? {backgroundColor: '#ffffff'} : {backgroundColor: '#1e1e1e'}} className="ion-no-padding ion-padding-bottom">
                    <IonItem>
                      <IonLabel>Unused Credits</IonLabel>
                      <IonBadge slot="end" color="success">{isSuperUser ? 'unlimited' : credits}</IonBadge>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Booked Credits</IonLabel>
                      <IonBadge slot="end" color="primary">{bookings.length}</IonBadge>
                    </IonItem>
                    <IonItem>
                      <IonLabel>Missed Bookings</IonLabel>
                      <IonBadge slot="end" color="danger">{missedBookingsNum}</IonBadge>
                    </IonItem>
                  </IonList>
                  <p className="ion-padding">Each boater has 3 credits to book 3 launch times in a 14-day period.  When you arrive to your launch time, a credit will be returned to you and you can book your next launch.  If you miss your launch time without cancelling ahead, your credit will not be returned for two weeks (<span style={{fontWeight: '800'}}>note:</span> weather allowances will be made).</p>
                  
                  {missedBookingsNum > 0 ?
                  <p className="ion-padding">Your missed bookings expire 14 days after their scheduled launch time, at which point you'll receive a new credit.</p>
                  : null}
                </div>
              }
              </IonCardContent>
            </IonCard>
            <Footer user={user.email}/>
          </div>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Cancel this booking?'}
          // subHeader={'Subtitle'}
          message={'This cannot be undone and you will have to book a new launch or removal.'}
          buttons={[
            {
              text: 'Nevermind',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                setShowAlert(false)
              }
            },
            {
              text: 'Yes, Cancel!',
              handler: () => {
                confirmCancel();
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Bookings;

import { IonContent, IonPage, IonCard, IonCardContent, IonGrid, IonInput,
        IonRow, IonCol, IonCardTitle, IonButton, IonItem, IonLabel} from '@ionic/react';
import React, {useState} from 'react';
import './Profile.css';
import Toolbar from '../components/Toolbar/Toolbar';
import firebaseApp from '../components/firebase';
import { useHistory } from 'react-router';
import { toast } from '../components/toast';


const ResetPassword = () => {
  const history = useHistory();
  const auth = firebaseApp.auth();
  const [ email, setEmail ] = useState();

  const reset = () => {
    auth.sendPasswordResetEmail(email).then(function() {
  // Email sent.
      toast('Password reset sent. Please check your email.')
      history.replace('/login')
    }).catch(function(error) {
      // An error happened.
      toast('That email address was not found. Please try again.')
    });
  }
  return (
    <IonPage>
      <Toolbar />
      <IonContent className="ion-align-items-center">
        <div className="inTheMiddle">
          <div className="loginArea">
            <IonCard className="confirmationCard align-items-center">
              <IonCardContent className="niceCardContent ion-padding">
                <IonGrid className="ion-no-padding">
                  <IonRow>
                    <IonCol size="12" className="oneCard">
                      <IonCardTitle className="thanks">Reset My Password</IonCardTitle>
                      <p className="ion-text-center">Enter your email in the form below and click "Send Password Reset Link".</p>
                      <div style={{height: 20}}/>
                      <IonItem >
                        <IonLabel position="stacked" className="inputLabel">Enter your email address</IonLabel>
                        <IonInput type="text" autocapitalize='off' placeholder={'example@email.com'} onIonChange={e => setEmail(e.target.value)}/>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow className="updateButtonRow">
                    <IonCol>
                      <IonButton onClick={reset}>Send Password Reset Link</IonButton>&nbsp;&nbsp;
                      <IonButton fill="outline" onClick={() => history.replace('/login')}>Cancel</IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;

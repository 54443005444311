import React from 'react';
import { IonButton} from '@ionic/react';
import { logoutUser } from '../firebase';
import { useHistory } from 'react-router';
import './Footer.css';

const Footer = (props) => {
    const history = useHistory();
    
    function logout() {
      logoutUser()
      history.replace('/')
    }
    
    return (      
      <div>
        <p className="ion-text-center loggedIn">You are logged in as {props.user}</p>
        <p className="ion-text-center">
          {/* <IonButton fill='clear' onClick={() => history.replace('/locations')}>Book Now!</IonButton> */}
          <IonButton
          href="mailto:help@nicelaunch.ca?Subject=NiceLaunch%20Support%20Request&Body=Please%20add%20details%20of%20the%20issue%20you%20are%20experiencing%20below:"
          fill='clear'>Help</IonButton>
          <IonButton
          onClick={logout}
          fill='clear'>Log Out</IonButton>
        </p>
      </div>
    );
  };

  export default Footer;